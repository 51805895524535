.switch {
  width: calc(170px / 3);
  height: calc(100px / 3);
  border-radius: calc(100px / 3);
  padding: calc(10px / 3);
  display: flex;
  cursor: pointer;
}

.switch.on {
  background-color: #53bf99;
  justify-content: flex-end;
}

.switch.off {
  background-color: #dddddd;
  justify-content: flex-start;
}

.switch div {
  width: calc(80px / 3);
  height: calc(80px / 3);
  background-color: #ffffff;
  border-radius: calc(200px / 3);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
}
